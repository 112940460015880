/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Grid, ArrowRight, Heart, BookOpen } from 'react-feather'

import Div from '../elements/Div'
import H4 from '../elements/H4'
import P from '../elements/P'
import H5 from '../elements/H5'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomDiv from '../lib/generate/page-section'
import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const Main = ({ div }) => (
  <div
    sx={{
      minHeight: '16rem',
    }}
  >
    <section 
      sx={{
        width: '100%',
        backgroundColor: div.parentBg
      }}
    >
      <div sx={{
          backgroundImage: 'url(' + div.backgroundImage + ')',
          backgroundSize: 'cover',
          py: [5,6,7]
      }}>
      </div>
  <article sx={{
    marginTop: div.marginTop + 'px',
    bg: div.backgroundColor,
    color: div.color,
  }}>
      <div
        sx={{
          filter: `blur(${div.blur}px)`,
          opacity: div.opacity / 100,
          //mixBlendMode: div.mixBlendMode,
          //backgroundImage: 'url(' + div.backgroundImage + ')',
          //backgroundPositionX: div.backgroundPositionX,
          //backgroundPositionY: div.backgroundPositionY,
          display: div.display,
          fontWeight: div.fontWeight,
          fontSize: [
            div.fontSize + 'px', 
            div.fontSizeM+'px', 
            div.fontSizeL+'px'
        ],
          fontStyle: div.fontStyle,
          alignItems: div.alignItems,
          textAlign: div.textAlign,
          textTransform: div.textTransform,
          paddingRight: div.paddingRight + 'px',
          paddingLeft: div.paddingLeft + 'px',
          paddingTop: div.paddingTop + 'px',
          //paddingBottom: div.paddingBottom + 'px',
          backgroundColor: div.bg,
          backgroundSize: 'cover',
          letterSpacing: div.letterSpacing / 100 + 'em',
          lineHeight: div.lineHeight / 100,
         // WebkitBackgroundClip: 'text',
          //WebkitTextFillColor: div.webkitFill,
          fontFamily: div.fontFamily,
          color: div.headlineColor
        }}
      >
        {div.text}
      </div>
      <p sx={{
          paddingRight: div.paddingRight + 'px',
          paddingLeft: div.paddingLeft + 'px',
          paddingBottom: div.paddingBottom + 'px',
          lineHeight: div.lineHeight2 / 100,
          fontSize: [
            div.subtitleFontSize,
            div.subtitleFontSizeM,
            div.subtitleFontSizeL,
          ],
          maxWidth: '40em'
      }}>
        {div.text2}
      </p>
      </article>
    </section>
  </div>
)

const GridItem = ({ div, ...props }) => (
  <div
    sx={{
      width: '25%',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '16px',
      height: '46vh'
    }}
    {...props}
  >
    <a
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      }}
    >
    <section 
      sx={{
        width: '100%',
        backgroundColor: div.parentBg
      }}
    >
      <div sx={{
          backgroundImage: 'url(' + div.backgroundImage + ')',
          backgroundSize: 'cover',
          py: [4,5,5]
      }}>
      </div>
  <article sx={{
    marginTop: div.marginTop + 'px',
    bg: div.backgroundColor,
    color: div.color,
  }}>
      <div
        sx={{
          filter: `blur(${div.blur}px)`,
          opacity: div.opacity / 100,
          //mixBlendMode: div.mixBlendMode,
          //backgroundImage: 'url(' + div.backgroundImage + ')',
          //backgroundPositionX: div.backgroundPositionX,
          //backgroundPositionY: div.backgroundPositionY,
          display: div.display,
          fontWeight: div.fontWeight,
          fontSize: div.fontSize + 'px', 
          fontStyle: div.fontStyle,
          alignItems: div.alignItems,
          textAlign: div.textAlign,
          textTransform: div.textTransform,
          paddingRight: div.paddingRight + 'px',
          paddingLeft: div.paddingLeft + 'px',
          paddingTop: div.paddingTop + 'px',
          //paddingBottom: div.paddingBottom + 'px',
          backgroundColor: div.bg,
          backgroundSize: 'cover',
          letterSpacing: div.letterSpacing / 100 + 'em',
          lineHeight: div.lineHeight / 100,
         // WebkitBackgroundClip: 'text',
          //WebkitTextFillColor: div.webkitFill,
          fontFamily: div.fontFamily,
          color: div.headlineColor
        }}
      >
        {div.text}
      </div>
      <p sx={{
          paddingRight: div.paddingRight + 'px',
          paddingLeft: div.paddingLeft + 'px',
          paddingBottom: div.paddingBottom + 'px',
          lineHeight: div.lineHeight2 / 100,
          fontSize: div.subtitleFontSize, 
          maxWidth: '40em'
      }}>
        {div.text2}
      </p>
      </article>
    </section>
    </a>
  </div>
)

const GridView = ({ pins, onSelect }) => (
  <Flex
    sx={{
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingTop: '32px',
      paddingBottom: '32px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }}
  >
    {Array(8)
      .fill(0)
      .map((_, i) => {
        const baseDiv = randomDiv()
        const divWithPins = { ...baseDiv, ...pins }
        const div = {
          ...divWithPins
        }

        return <GridItem key={i} onClick={() => onSelect(div)} div={div} />
      })}
  </Flex>
)

const Sidebar = ({ onChange, value, pins, onPin }) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  return (
    <>
      <div sx={{ pt: 2 }}></div>
      <SidebarHeading>Page Section</SidebarHeading>
      <Color
        label="Canvas Bg"
        value={value.parentBg}
        onChange={changeValue('parentBg')}
        active={pins.parentBg}
        onLock={() => onPin('parentBg', value.parentBg)}
        width={128}
      />
      <SectionBorder my={3} />
      <H5 fontSize={0} mb={2}>
        Content
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.text}
          onClick={() => onPin('text', value.text)}
        />
        <Label>Title</Label>
        <Input
          width={1}
          value={value.text}
          type="text"
          onChange={changeValue('text')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.text2}
          onClick={() => onPin('text2', value.text2)}
        />
        <Label>Text</Label>
        <Input
          width={1}
          value={value.text2}
          type="text"
          onChange={changeValue('text2')}
        />
      </Flex>
      <Color
        label="Background"
        value={value.backgroundColor}
        onChange={changeValue('backgroundColor')}
        active={pins.backgroundColor}
        onLock={() => onPin('backgroundColor', value.backgroundColor)}
        width={128}
      />
      <Color
        label="Title"
        value={value.headlineColor}
        onChange={changeValue('headlineColor')}
        active={pins.headlineColor}
        onLock={() => onPin('headlineColor', value.headlineColor)}
        width={128}
      />
      <Color
        label="Text"
        value={value.color}
        onChange={changeValue('color')}
        active={pins.color}
        onLock={() => onPin('color', value.color)}
        width={128}
      />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.backgroundImage}
          onClick={() => onPin('backgroundImage', value.backgroundImage)}
        />
        <Label>Image</Label>
        <Input
          width={1}
          value={value.backgroundImage}
          type="text"
          onChange={changeValue('backgroundImage')}
        />
      </Flex>
      <SectionBorder my={3} />
      <H5 fontSize={0} mb={2}>
        Typography
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontFamily}
          onClick={() => onPin('fontFamily', value.fontFamily)}
        />
        <Label>Font Family</Label>
        <Input
          width={1}
          value={value.fontFamily}
          type="text"
          onChange={changeValue('fontFamily')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontSize}
          onClick={() => onPin('fontSize', value.fontSize)}
        />
        <Label>Font Size</Label>
        <Input
          min={20}
          max={256}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSize)}
          type="range"
          onChange={changeValue('fontSize')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          mr={3}
          fontSize={0}
          children={value.fontSize}
        />
        <Input
          min={20}
          max={256}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSizeM)}
          type="range"
          onChange={changeValue('fontSizeM')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          mr={3}
          fontSize={0}
          children={value.fontSizeM}
        />
        <Input
          min={20}
          max={512}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.fontSizeL)}
          type="range"
          onChange={changeValue('fontSizeL')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.fontSizeL}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.textTransform}
          onClick={() => onPin('textTransform', value.textTransform)}
        />
        <Label>Text Transform</Label>
        <select
          value={value.textTransform}
          onChange={changeValue('textTransform')}
        >
          <option>capitalize</option>
          <option>uppercase</option>
          <option>lowercase</option>
          <option>none</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.textAlign}
          onClick={() => onPin('textAlign', value.textAlign)}
        />
        <Label>Text Align</Label>
        <select value={value.textAlign} onChange={changeValue('textAlign')}>
          <option>left</option>
          <option>right</option>
          <option>center</option>
          <option>justify</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontStyle}
          onClick={() => onPin('fontStyle', value.fontStyle)}
        />
        <Label>Font Style</Label>
        <select value={value.fontStyle} onChange={changeValue('fontStyle')}>
          <option>normal</option>
          <option>italic</option>
          <option>oblique</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.lineHeight}
          onClick={() => onPin('lineHeight', value.lineHeight)}
        />
        <Label>Line Height</Label>
        <Input
          min={0}
          max={400}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.lineHeight)}
          type="range"
          onChange={changeValue('lineHeight')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          lineHeight={0}
          children={value.lineHeight / 100}
          fontSize={0}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.letterSpacing}
          onClick={() => onPin('letterSpacing', value.letterSpacing)}
        />
        <Label>Letter Spacing</Label>
        <Input
          min={-400}
          max={400}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.letterSpacing)}
          type="range"
          onChange={changeValue('letterSpacing')}
        />
        <Span
          width="80px"
          textAlign="right"
          fontSize={0}
          ml="auto"
          letterSpacing={0}
          children={value.letterSpacing / 100}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.fontWeight}
          onClick={() => onPin('fontWeight', value.fontWeight)}
        />
        <Label>Font Weight</Label>
        <select value={value.fontWeight} onChange={changeValue('fontWeight')}>
          <option>100</option>
          <option>200</option>
          <option>300</option>
          <option>400</option>
          <option>500</option>
          <option>600</option>
          <option>700</option>
          <option>800</option>
          <option>900</option>
        </select>
      </Flex>
      <SectionBorder my={3} />
      <H5 fontSize={0} mb={2}>
        Padding
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.paddingTop}
          onClick={() => onPin('paddingTop', value.paddingTop)}
        />
        <Label>Top</Label>
        <Input
          min={0}
          max={1024}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.paddingTop)}
          type="range"
          onChange={changeValue('paddingTop')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.paddingTop}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.paddingBottom}
          onClick={() => onPin('paddingBottom', value.paddingBottom)}
        />
        <Label>Bottom</Label>
        <Input
          min={0}
          max={1024}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.paddingBottom)}
          type="range"
          onChange={changeValue('paddingBottom')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.paddingBottom}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.paddingLeft}
          onClick={() => onPin('paddingLeft', value.paddingLeft)}
        />
        <Label>Left</Label>
        <Input
          min={0}
          max={1024}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.paddingLeft)}
          type="range"
          onChange={changeValue('paddingLeft')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.paddingLeft}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.paddingRight}
          onClick={() => onPin('paddingRight', value.paddingRight)}
        />
        <Label>Right</Label>
        <Input
          min={0}
          max={1024}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.paddingRight)}
          type="range"
          onChange={changeValue('paddingRight')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.paddingRight}
        />
      </Flex>
      <H5 fontSize={0} mb={2}>
        Margin
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.marginTop}
          onClick={() => onPin('marginTop', value.marginTop)}
        />
        <Label>Top</Label>
        <Input
          min={-64}
          max={128}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.marginTop)}
          type="range"
          onChange={changeValue('marginTop')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.marginTop}
        />
      </Flex>
      <SectionBorder my={4} />
      <H5 display='none' mb={1}>Css</H5>
      <Textarea
        display='none'
        bg="transparent"
        height={64}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          '.text-image { \n' +
          `  background-image: url(${value.backgroundImage});\n` +
          `  background-position-x: ${value.backgroundPositionX};\n` +
          `  background-position-y: ${value.backgroundPositionY};\n` +
          `  background-size: ${value.backgroundSize};\n` +
          `  -webkit-background-clip: text;\n` +
          `  -webkit-text-fill-color: transparent;\n` +
          '}'
        }
      />
      <H5 mb={1} display='none'>Js</H5>
      <Textarea
        bg="transparent"
        display='none'
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          "import styled from '@emotion/styled'\n\n" +
          "const TextImage = styled('p')({\n" +
          `  backgroundImage: 'url(${value.backgroundImage})',\n` +
          `  backgroundPositionX: '${value.backgroundPositionX}',\n` +
          `  backgroundPositionY: '${value.backgroundPositionY}',\n` +
          `  backgroundSize: '${value.backgroundSize}',\n` +
          `  WebkitBackgroundClip: 'text',\n` +
          `  WebkitTextFillColor: 'transparent',\n` +
          '\n})\n'
        }
      />
    </>
  )
}

export default ({ initialDiv, initialPins }) => {
  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem('textImageVotes')) || []
  } catch (e) {}

  const [div, setDiv] = useState(initialDiv || randomDiv())
  const [pins, setPins] = useState(initialPins || {})
  const [gridView, setGridView] = useState(false)

  const divWithPins = { ...div, ...pins }
  const fullDiv = {
    ...divWithPins
  }

  const upvote = async () => {
    if (gridView) {
      return
    }

    const { id } = await db
      .collection('components')
      .doc('textImages')
      .collection('votes')
      .add({
        up: true,
        data: fullDiv,
        metadata: { pins }
      })

    localStorage.setItem(
      'textImagesVotes',
      JSON.stringify(
        [
          {
            id
            //backgroundColor: fullGradient.backgroundColor,
            //backgroundImage: fullGradient.backgroundImage
          },
          ...votes
        ]
          .slice(0, 100)
          .filter(Boolean)
      )
    )

    setDiv(randomDiv())
  }
  const skip = () => {
    setDiv(randomDiv())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = div => {
    setDiv(div)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main div={fullDiv} />
        )
      }
      sidebar={
        <Sidebar
          value={fullDiv}
          pins={pins}
          onChange={(key, value) => {
            setDiv({
              ...div,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <div
            sx={{
              color: 'black',
              textAlign: 'center',
              //padding: 20,
              display: 'flex',
              width: '100%',
              alignItems: 'center'
              //borderBottom: '1px solid rgba(0,0,0,.1)'
            }}
          >
            {false && (
              <Link
                to="/div/votes"
                sx={{
                  color: 'black',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textDecoration: 'none'
                }}
              >
                <BookOpen size={20} />
                <span sx={{ paddingLeft: 10 }}>Previous likes</span>
              </Link>
            )}
          </div>
          <Flex>
            <VoteButton
              title="Save"
              disabled={gridView}
              sx={{
                display: 'none',
                '&:disabled': {
                  cursor: 'not-allowed',
                  opacity: 0.4
                }
              }}
              onClick={upvote}
            >
              <Heart size={20} />
              <Hidden>Save</Hidden>
            </VoteButton>
            <VoteButton width={1/2} title="Next" onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
            <VoteButton width={1/2} title="Grid view" onClick={toggleGridView}>
              <Grid size={20} />
              <Hidden>Grid view</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
