const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const adaptiveGradient = require('adaptive-gradient').default
const { randomInt } = require('../util')
const randomBoxShadow = require('./box-shadow')

const textAlign = 'left'

const textDecorationStyle = sample([
  'solid',
  'double',
  'dotted',
  'dashed',
  'wavy'
])

const textDecorationLine = sample([
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'underline',
  'overline',
  'line-through',
  'blink'
])

const backgroundImageRepeat = sample([
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'repeat',
  'repeat-x',
  'repeat-y',
  'space'
])

const backgroundSizes = [
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'cover',
  'contain',
  '8px',
  '16px',
  '24px',
  '32px',
  '64px',
  '10%',
  '25%',
  '50%',
  '75%',
  '100%',
  '200%'
]

const backgroundPositionX = sample(['left', 'center', 'right', '25%', '75%'])

const backgroundPositionY = sample(['top', 'center', 'bottom', '25%', '75%'])

const blendModes = ['normal']

const blendMode = sample(blendModes)

const borderStyles = [
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'hidden',
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset'
]

const images = [
  'https://mrmrs.cc/pix/t/bg1.jpg',
  //'https://mrmrs.cc/pix/t/bg2.jpg',
  'https://mrmrs.cc/pix/t/bg3.jpg',
  'https://mrmrs.cc/pix/t/bg4.jpg',
  'https://mrmrs.cc/pix/t/bg5.jpg',
  'https://mrmrs.cc/pix/t/bg6.jpg',
  'https://mrmrs.cc/pix/t/bg7.jpg',
]

const generateDiv = color => {
  const shadowBool = randomInt(0, 1)
  var boxShadow = 'none'
  if (shadowBool > 0) {
    boxShadow = randomBoxShadow()
  }

  color = color || randomHex()

  const outerRadius = randomInt(0, 100)

  const radiusBool = randomInt(0, 10)

  const backgroundImage = sample(images)
  const backgroundSize = sample(backgroundSizes)

  let outerRadiusMax = outerRadius
  let outerRadiusMin = outerRadius
  let innerRadiusMax = outerRadius
  let innerRadiusMin = outerRadius

  if (radiusBool > 5) {
    outerRadiusMax = randomInt(60, 100)
    outerRadiusMin = randomInt(10, 60)
    innerRadiusMax = randomInt(50, 100)
    innerRadiusMin = randomInt(10, 60)
  }

  const borderWidth = randomInt(0, 8)
  const outerBorderWidth = randomInt(0, 6)

  const size = randomInt(32, 80)
  const padding = 32
  const margin = 0

  const borderStyle = sample(borderStyles)
  const fontWeight = sample([
    400,
    400,
    500,
    500,
    600,
    600,
    600,
    700,
    700,
    700,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    800,
    900,
    900,
    900,
    900,
    900
  ])


  const fontSize = [
    20,
    24,
    32,
    48,
    54,
    64,
    80,
    96,
  ]

  const fontSizeCount = randomInt(0,4)

  const fontSize2 = sample([
    18,20,24,28,32
  ])

  const textFills = [
    'transparent',
    '#000',
    '#fff',
    '#e20074',
  ]

  const themes = [
    {
      text: '#000000',
      bg: '#ffffff',
      feature: '#e20074',
    },
    {
      text: '#000000',
      bg: '#e20074',
      feature: '#ffffff',
    },
    {
      text: '#ffffff',
      bg: '#e20074',
      feature: '#000000',
    },
    {
      text: '#ffffff',
      bg: '#000000',
      feature: '#e20074',
    },
    {
      text: '#e20074',
      bg: '#000000',
      feature: '#fff',
    },
    {
      text: '#e20074',
      bg: '#ffffff',
      feature: '#000000',
    },
    {
      text: '#e20074',
      bg: '#ffffff',
      feature: '#e20074',
    },
    {
      text: '#000000',
      bg: '#ffffff',
      feature: '#000000',
    },
    {
      text: '#ffffff',
      bg: '#000000',
      feature: '#ffffff',
    },
    {
      text: '#e20074',
      bg: '#000000',
      feature: '#e20074',
    },
  ]

  const themeCount = randomInt(0,themes.length-1)

  const boxSizing = sample(['border-box', 'content-box'])

  const div = {
    boxSizing: boxSizing,
    display: 'block',
    fontWeight: fontWeight,
    fontSize: fontSize[fontSizeCount],
    fontSizeM: fontSize[fontSizeCount+1],
    fontSizeL: fontSize[fontSizeCount+1],
    subtitleFontSize: 18,
    subtitleFontSizeM: 24,
    subtitleFontSizeL: fontSize2,
    alignItems: 'center',
    bg: 'transparent', // set background of wrapper color
    //startColorHinting,
    //endColor: '#' + endColor,
    //endColorHinting,
    spread: 80,
    height: size,
    width: size,
    borderWidth: borderWidth,
    outerBorderWidth: borderWidth > 0 ? 0 : outerBorderWidth,
    padding: padding,
    paddingRight: padding,
    paddingLeft: padding,
    paddingTop: padding * 2,
    paddingBottom: padding * 2,
    marginRight: margin,
    marginLeft: margin,
    marginTop: margin,
    marginBottom: margin,
    topRightRadius: outerRadiusMin,
    topLeftRadius: outerRadiusMax,
    bottomRightRadius: outerRadiusMax,
    bottomLeftRadius: outerRadiusMin,
    mirrorTopRightRadius: outerRadiusMax,
    mirrorTopLeftRadius: outerRadiusMin,
    mirrorBottomRightRadius: outerRadiusMin,
    mirrorBottomLeftRadius: outerRadiusMax,
    boxShadow: boxShadow,
    borderTopWidth: '',
    borderTopStyle: '',
    borderTopColor: '#000',
    borderBottomWidth: '',
    borderBottomStyle: '',
    borderBottomColor: '#ff00ff',
    borderLeftWidth: '',
    borderLeftStyle: '',
    borderLeftColor: '#ffff33',
    borderRightWidth: '',
    borderRightStyle: '',
    borderRightColor: '#ee0044',
    borderImageSource: '',
    borderImageSlice: '',
    borderImageWidth: '',
    borderImageOutset: '',
    borderImageRepeat: '',
    outlineStyle: '',
    outlineWidth: '',
    outlineColor: '',
    text: sample([
    'Bring your phone to T-Mobile', 
    'The only nationwide 5G network is here.'
  ]),
    text2: "It’s an easy and affordable way to join us. But first, let’s check your phone’s IMEI number to make sure it will give you a great experience on our network.",
    sectionTitle: "Check compatibility with your phone's IMEI.",
    sectionSubTitle: "Every phone has a unique IMEI number—15 digits that tell you the make, model, whether it’s locked or unlocked, and if it's ready to use on our network.",
    opacity: 100,
    textAlign: 'left',
    textShadow: 'center',
    lineHeight: 100,
    lineHeight2: 150,
    letterSpacing: 0,
    textTransform: '',
    textShadowBlur: '',
    textShadowOffsetX: '',
    textShadowOffsetY: '',
    textShadowColor: '#000',
    fontStyle: '',
    whiteSpace: '',
    backgroundImage: backgroundImage,
    backgroundImageRepeat: backgroundImageRepeat,
    backgroundImageY: backgroundPositionY,
    backgroundImageX: backgroundPositionX,
    backgroundSize: backgroundSize,
    animationDelay: '',
    animationDirection: '',
    animationDuration: '',
    animationFillMode: '',
    animationIterationCount: '',
    animationName: '',
    animationPlayState: '',
    animationTimingFunction: '',
    maskClip: '',
    maskComposite: '',
    maskImage: '',
    maskMode: '',
    maskOrigin: '',
    maskRepeat: '',
    maskSize: '',
    maskType: '',
    mixBlendMode: 'normal',
    blur: 0,
    fontStyle: 'normal',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    fontFamily: 'system-ui, sans-serif',
    //webkitFill: textFills
    //webkitFill: 
    color: themes[themeCount].text,
    backgroundColor: themes[themeCount].bg,
    headlineColor: themes[themeCount].feature,
    parentBg: themes[themeCount].bg,
  }

  return {
    ...div
    //backgroundImage: toGradient(gradient)
  }
}

module.exports = generateDiv
